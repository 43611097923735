<template>
  <moe-page title="查看赠品">
    <moe-form :showBack="false">
      <moe-describe-list title="基本信息" :col="1">
        <moe-describe-item label="活动名称">{{ buyGiftData.name }}</moe-describe-item>
        <moe-describe-item label="活动时间">{{ buyGiftData.startTime }} 至 {{ buyGiftData.endTime }} </moe-describe-item>
        <moe-describe-item label="活动类型">{{ $moe_format.getPresaleLevel(buyGiftData.level) }}</moe-describe-item>
      </moe-describe-list>
    </moe-form>

    <moe-describe-list title="活动商品与赠品" :col="1"></moe-describe-list>
    <div style="height: 700px;min-height: 700px;" class="df fdc">
      <moe-table
        :stripe="true"
        :numberShow="false"
        :data="buyGiftData.configList"
        :mode="false"
        :showPage="false"
        :params="{ pageNum: 1 }"
        emptyText="请添加商品"
        rowKey="goodsId">
        <el-table-column label="商品信息" min-width="200">
          <template slot-scope="{ row }">
            <div class="df aic jcc">
              <moe-image :src="row.coverUrl" width="80px" height="80px" />
              <div class="df1 tal ml-10">
                <p class="fwb">{{ row.name }}</p>
                <p class="font-12 color-info">商品编号: {{ row.id }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="销售价" prop="salePrice" min-width="150">
          <template slot-scope="{ row }">
            {{ `¥ ${row.minPrice}${$moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : ''}` }}
          </template>
        </el-table-column>
        <el-table-column label="库存" prop="stock" width="80" />
        <el-table-column label="赠品" min-width="300">
          <template slot-scope="{ row }">
            <div class="df aic jcc">
              <div class="df aic fww" v-if="buyGiftData.level === 'GOOD'">
                <div class="df aic mb-20 mr-20" v-for="(giftItem, giftIndex) in row.giftConfigList" :key="giftIndex">
                  <div class="df aic jcc">
                    <div class="buy-gift-detail__gift__image">
                      <moe-image :src="giftItem.goodsItemCover" width="80px" height="80px" />
                      <div class="buy-gift-detail__gift__readonly" v-if="!giftItem.running">
                        <div class="buy-gift-detail__gift__readonly__text">已失效</div>
                      </div>
                    </div>
                    <div class="df fdc jcfs">
                      <div>赠品件数</div>
                      <div>{{ giftItem.num }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <el-button
                v-else-if="buyGiftData.level === 'SKU'"
                @click="req_queryShopGoodsById(row)"
                icon="el-icon-circle-check"
                type="text">
                查看规格赠品
              </el-button>
            </div>
          </template>
        </el-table-column>
      </moe-table>
    </div>

    <moe-form :showBack="false" :showClose="true"></moe-form>

    <moe-dialog :show="skuForm.showDialog" title="查看规格赠品" width="70%" @close="handleSkuClose()" :closeOnClickModal="false">
      <div class="dialog_content">
        <div style="height: 500px;min-height: 500px;" class="df fdc">
          <moe-table
            :stripe="true"
            :numberShow="false"
            :data="skuForm.list"
            :mode="false"
            :showPage="false"
            :params="{ pageNum: 1 }"
            rowKey="id">
            <el-table-column label="编号" prop="id" width="80" />
            <el-table-column label="规格" min-width="150">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="销售价" prop="salePrice" width="150">
              <template slot-scope="{ row }">
                {{ `¥ ${row.salePrice}` }}
              </template>
            </el-table-column>
            <el-table-column label="库存" prop="stock" width="80" />
            <el-table-column label="赠品" min-width="300">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <div class="df aic fww">
                    <div class="df aic mb-20 mr-20" v-for="(giftItem, giftIndex) in row.giftConfigList" :key="giftIndex">
                    <div class="df aic jcc">
                      <div class="buy-gift-detail__gift__image">
                        <moe-image :src="giftItem.goodsItemCover" width="80px" height="80px" />
                        <div class="buy-gift-detail__gift__readonly" v-if="!giftItem.running">
                          <div class="buy-gift-detail__gift__readonly__text">已失效</div>
                        </div>
                      </div>
                      <div class="df fdc jcfs">
                        <div>赠品件数</div>
                        <div>{{ giftItem.num }}</div>
                      </div>
                    </div>
                </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </moe-table>
        </div>
      </div>
      <template slot="footer">
        <el-button @click="handleSkuClose()">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageBuyGiftDetail',
  data() {
    return {
      buyGiftData: {},
      skuForm: {
        showDialog: false,
        list: [],
      }
    }
  },
  methods: {
    handleSkuClose() {
      this.skuForm.showDialog = false;
    },
    /** 获取买赠活动详情 */
    req_getBuyGiftDetail() {
      if (this.$route.query.id) {
        this.$moe_api.BUYGIFT_API.getBuyGiftDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            this.buyGiftData = {
              ...data.result,
              configList: []
            };
            if (data.result.configList.length) {
              this.req_getQueryShopGoodsByIds(data.result.configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    req_getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId');
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          switch (this.buyGiftData.level) {
            case 'GOOD':
              this.buyGiftData.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                }
              })
              break;
            case 'SKU':
              this.buyGiftData.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                let giftConfigList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId).map((giftConfigItem) => {
                  return {
                    ...giftConfigItem,
                    id: giftConfigItem.goodsItemId,
                    giftConfigList: giftConfigItem.giftConfigList.map((giftConfigListItem) => {
                      return {
                        ...giftConfigListItem,
                        id: giftConfigListItem.giftId,
                      }
                    })
                  }
                })
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  giftConfigList: giftConfigList.length ? giftConfigList : [],
                }
              })
              break;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 查询商品详情 */
    req_queryShopGoodsById({ id, giftConfigList }) {
      console.log('giftConfigList', giftConfigList)
      this.$moe_api.GOODS_API.queryShopGoodsById({ id }).then((data) => {
        let goodsItem = this.$moe_lodash.cloneDeepData(data.result.goodsItem.filter(({ delFlag }) => delFlag === 0));
        this.skuForm.list = giftConfigList.map((giftConfigItem) => {
          let findItem = goodsItem.find((specItem) => giftConfigItem.goodsItemId === specItem.id)
          return {
            ...giftConfigItem,
            ...findItem,
          }
        })

        this.skuForm.showDialog = true;
      })
    },
  },
  mounted() {
    this.req_getBuyGiftDetail();
  },
}
</script>

<style lang="scss" scoped>
.buy-gift-detail__gift {
  &__image {
    position: relative;
    height: 80px;
    margin-right: 5px;
    border-radius: 10px;
    overflow: hidden;
  }
  &__readonly {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &__text {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>